<script>
import StoreAffiliateOptionsForm from '@app/components/StoreAffiliateOptionsForm.vue';

export default {
  components: {
    StoreAffiliateOptionsForm,
  },
};
</script>

  <template>
    <div>
      <StoreAffiliateOptionsForm />
    </div>
  </template>
